<template>
  <div class="content-box">
    <el-form :inline="true" class="my_element_form">
      <el-form-item label="描述:">
        <el-input size="medium" v-model="initialParams.imgDesc" placeholder="请输入图片描述关键词" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="medium" @click="handleSearch">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="info" size="medium" @click="handleReset">重置</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="success" size="medium" @click="addForm.dialogVisible = true">新增</el-button>
      </el-form-item>
    </el-form>
    <!--  -->
    <el-table :data="tableData" :header-cell-style="{background: '#eee', color: '#333'}" style="width: 100%;" highlight-current-row stripe v-loading="initialParams.loading" >
      <el-table-column label="描述" prop="imgDesc" width="200px"></el-table-column>
      <el-table-column label="图片" width="120px" >
        <template slot-scope="scope">
          <el-image
            fit="contain"
            style="width: 120px; height: 80px"
            :src="$imgBaseUrl + scope.row.image"
            :preview-src-list="[$imgBaseUrl + scope.row.image]">
          </el-image>
          <!-- <el-image :src="$imgBaseUrl + scope.row.image" ></el-image> -->
        </template>
      </el-table-column>
      <el-table-column label="链接" width="300px">
        <template slot-scope="scope">
          <el-link type="primary" @click="handleHref(scope.row.url)">{{scope.row.url}}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="排序值" >
        <template slot-scope="scope">
          <span style="color: #409EFF; text-decoration: underline; cursor:pointer" v-if="!scope.row.isShow" @dblclick="handleShow(scope.row)">{{scope.row.sort}}</span>
          <el-input v-model="scope.row.sort" autofocus v-if="scope.row.isShow" type="number" @blur="handleBlur(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="状态" >
          <template slot-scope="scope">
            <span style="color: #409eff" v-if="scope.row.status == 1">未发布</span>
            <span v-if="scope.row.status == 2">已发布</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <!-- <el-button size="mini" type="primary" plain @click="handleDet(scope.row.id)">删除</el-button> -->
            <el-button size="mini" type="primary" plain @click="handleEdit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" plain v-if="scope.row.status == 2" @click="handleIssue(scope.row, 1)">取消发布</el-button>
            <el-button size="mini" type="success" plain v-if="scope.row.status != 2" @click="handleIssue(scope.row, 2)">发布</el-button>
          </template>
        </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      @size-change="handleCurrentChange"
      @current-change="handleCurrentChange"
      :current-page.sync="initialParams.pageNo"
      :page-size="initialParams.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="initialParams.total"
      background>
    </el-pagination>
    <!-- 新增 -->
    <el-dialog title="新增轮播图" :visible.sync="addForm.dialogVisible" @close="DialogClosed('add')">
      <el-form :model="addForm" :rules="rulesAddForm" ref="addForm" label-width="80px" >
        <el-form-item label="上传图片" prop="imgUrl">
          <el-checkbox-group v-model="addForm.imgUrl" v-show="false"></el-checkbox-group>
          <My-Uploadimg v-if="addForm.dialogVisible" ref="UploadimgAdd" :limit="1" @UploadImgFun="UploadImgFunAdd"></My-Uploadimg>
          <p>只能上传jpg/png文件, 建议上传图片尺寸为宽:480，高320</p>
        </el-form-item>
        <el-form-item label="描述" >
          <el-input type="textarea" show-word-limit maxlength="300" v-model="addForm.imgDesc" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="链接" >
          <el-input v-model="addForm.link" placeholder="请输入详细地址链接"></el-input>
        </el-form-item>
        <el-form-item label="直接发布">
          <el-switch v-model="addForm.status"></el-switch>
        </el-form-item>
        <el-form-item label="排序值" prop="sort">
          <el-input v-model="addForm.sort" type="number" placeholder="请输入排序值"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addForm.dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="addForm.loading" @click="handleAddFormSave()">确定</el-button>
      </span>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog title="编辑轮播图" :visible.sync="editForm.dialogVisible" >
      <el-form :model="editForm" :rules="rulesEditForm" ref="rulesEditForm" label-width="80px" >
        <el-form-item label="上传图片" prop="imgUrl">
          <el-checkbox-group v-model="editForm.imgUrl" v-show="false"></el-checkbox-group>
          <My-Uploadimg v-if="editForm.dialogVisible" ref="UploadimgEdit" :callbackShow="editForm.imgUrl" :limit="1" @UploadImgFun="UploadImgFunEdit"></My-Uploadimg>
           <p>只能上传jpg/png文件, 建议上传图片尺寸为宽:1920，高300</p>
        </el-form-item>
        <el-form-item label="描述" >
          <el-input type="textarea" show-word-limit maxlength="300" v-model="editForm.imgDesc" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="链接" >
          <el-input v-model="editForm.link" placeholder="请输入详细地址链接"></el-input>
        </el-form-item>
        <el-form-item label="直接发布">
          <el-switch v-model="editForm.status"></el-switch>
        </el-form-item>
        <el-form-item label="排序值" prop="sort">
          <el-input class="no-number" type="number" v-model="editForm.sort" placeholder="请输入排序值"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editForm.dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="editForm.loading" @click="handleEditFormSave">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MyUploadimg from '@/components/uploadimg/Uploadimg'
import { AddCarousel, DeleteCarousel, UpdateCarousel, GetCarouselList } from '@/api/http.js'
export default {
  components: { MyUploadimg },
  data () {
    return {
      tableData: [],
      initialParams: {
        imgDesc: '',
        loading: false,
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      // 新增
      addForm: {
        dialogVisible: false,
        loading: false,
        imgUrl: '',
        imgDesc: '',
        link: '',
        status: false,
        sort: ''
      },
      rulesAddForm: {
        imgUrl: [{ required: true, message: '请上传图片', trigger: 'change' }],
        sort: [{ required: true, message: '请输入该图片的排序值', trigger: 'blur' }]
      },
      // 编辑
      editForm: {
        id: '',
        dialogVisible: false,
        loading: false,
        imgUrl: '',
        imgDesc: '',
        link: '',
        status: false,
        sort: ''
      },
      rulesEditForm: {
        imgUrl: [{ required: true, message: '请上传图片', trigger: 'change' }],
        sort: [{ required: true, message: '请输入该图片的排序值', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.initialData()
  },
  methods: {
    // 跳转
    handleHref(url) {
      window.open(url)
    },
    // 双击显示
    handleShow(row) {
      console.log(row)
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].id == row.id) {
          this.tableData[i].isShow = true
        }
      }
    },
    // 失焦提交修改
    handleBlur(row) {
      const params = {
        id: row.id,
        image: row.imgUrl,
        imgDesc: row.imgDesc,
        sort: row.sort,
        url: row.url,
        status: row.status
      }
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].id == row.id) {
          this.tableData[i].isShow = false
        }
      }
      UpdateCarousel(params).then(res => {
        if (res.code != '000') return this.$message({ type: 'error', message: res.msg })
        this.$message({ type: 'success', message: '修改成功' })
        this.initialData()
      }).catch(() => { this.initialData() })
    },
    // 发布
    handleIssue(row, type) {
      var str = ''
      if (type == 1) {
        str = '取消'
      }
      this.$confirm(`是否${str}发布?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          id: row.id,
          image: row.image,
          imgDesc: row.imgDesc,
          status: type,
          sort: row.sort,
          url: row.url
        }
        UpdateCarousel(params).then(res => {
          if (res.code != '000') return this.$message({ type: 'error', message: res.msg })
          this.$message({ type: 'success', message: `${str}发布成功` })
          this.initialData()
        })
      }).catch(() => {})
    },
    // 删除
    handleDet(id) {
      DeleteCarousel({ id }).then(res => {})
    },
    // 编辑显示
    handleEdit (row) {
      console.log(row)
      var status = ''
      if (row.status === 1) {
        status = false
      } else {
        status = true
      }
      this.editForm.id = row.id
      this.editForm.imgUrl = row.image
      this.editForm.imgDesc = row.imgDesc
      this.editForm.link = row.url
      this.editForm.status = status
      this.editForm.sort = row.sort
      this.editForm.dialogVisible = true
    },
    // 编辑提交
    handleEditFormSave () {
      this.$refs.rulesEditForm.validate(valid => {
        if (!valid) return false
        const params = {
          id: this.editForm.id,
          image: this.editForm.imgUrl,
          imgDesc: this.editForm.imgDesc,
          sort: this.editForm.sort,
          url: this.editForm.link,
          status: this.editForm.status ? 2 : 1
        }
        this.editForm.loading = true
        UpdateCarousel(params).then(res => {
          this.editForm.loading = false
          if (res.code != '000') return this.$message({ type: 'error', message: res.msg })
          this.$message({ type: 'success', message: '修改成功' })
          this.editForm.dialogVisible = false
          this.initialData()
        })
      })
    },
    // 新增提交
    handleAddFormSave() {
      this.$refs.addForm.validate(valid => {
        if (!valid) return false
        const params = {
          image: this.addForm.imgUrl,
          imgDesc: this.addForm.imgDesc,
          sort: this.addForm.sort,
          status: this.addForm.status ? 2 : 1,
          url: this.addForm.link
        }
        this.addForm.loading = true
        AddCarousel(params).then(res => {
          this.addForm.loading = false
          if (res.code != '000') return this.$message({ type: 'error', message: res.msg })
          this.$message({ type: 'success', message: '新增成功' })
          this.addForm.dialogVisible = false
          this.initialParams.pageNo = 1
          this.initialData()
        })
      })
    },
    // 新增重置表单
    DialogClosed (type) {
      if (type === 'edit') {
        this.editForm.loading = false
      } else if (type === 'add') {
        this.addForm.imgUrl = ''
        this.addForm.imgDesc = ''
        this.addForm.link = ''
        this.addForm.status = false
        this.addForm.sort = ''
        this.addForm.loading = false
        this.$refs.addForm.resetFields()
      }
    },
    initialData () {
      const params = {
        imgDesc: this.initialParams.imgDesc,
        pageNo: this.initialParams.pageNo,
        pageSize: this.initialParams.pageSize
      }
      this.initialParams.loading = true
      GetCarouselList(params).then(res => {
        this.initialParams.loading = false
        console.log(res)
        if (res.code !== '000') return this.$message({ type: 'error', message: res.msg })
        for (let i = 0; i < res.result.records.length; i++) {
          res.result.records[i].isShow = false
        }
        this.tableData = res.result.records
        this.initialParams.total = res.result.total
      }).catch((err) => {
        console.log(err)
      })
    },
    // 分页
    handleCurrentChange(val) {
      this.initialParams.pageNo = val
      this.initialData()
    },
    // 搜索
    handleSearch () {
      this.initialParams.pageNo = 1
      this.initialData()
    },
    // 搜索条件重置
    handleReset () {
      this.initialParams.imgDesc = ''
      this.initialParams.pageNo = 1
      this.initialData()
    },
    // 组件接收传值
    UploadImgFunAdd(val) {
      console.log('添加回调', val)
      this.addForm.imgUrl = val
    },
    UploadImgFunEdit(val) {
      console.log('编辑回调', val)
      this.editForm.imgUrl = val
    }
  }
}
</script>

<style lang="less" scoped>
.el-pagination {
  margin-top: 20px;
  text-align: right;
}
</style>
